/* Montserrat Thin */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/static/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

/* Montserrat Thin italic */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/static/Montserrat-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

/* Montserrat Extra Light */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/static/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

/* Montserrat Extra Light Italic */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/static/Montserrat-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

/* Montserrat Light */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/static/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* Montserrat Light Italic */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/static/Montserrat-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

/* Montserrat Regular */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Montserrat Italic */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/static/Montserrat-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

/* Montserrat Medium */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/static/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* Montserrat Medium Italic */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/static/Montserrat-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

/* Lato Regular */
@font-face {
  font-family: 'Lato, sans-serif';
  src: url('../assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

html {
  -ms-overflow-style: none;
  overscroll-behavior-y: none;
  scrollbar-width: none;
}
html::-webkit-scrollbar {
  width: 0;
}

body {
  margin: 0;
  overscroll-behavior-y: none;
  font-family:
    'Montserrat',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*:focus {
  box-shadow: none !important;
}

*[data-focus] {
  box-shadow: none !important;
}
